import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Admin, Resource, ListGuesser, defaultTheme } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server'
import {UserCreate, UserEdit, UserList} from './users/index'
import {PlansCreate,PlansEdit,PlansList}from './plans/index'
import {dataProvider} from './dataProvider'
import authProvider from './authProvider'
import MyLoginPage from './loginPage';
//const dataProvider = jsonServerProvider('http://localhost:3000/admin')
const lightTheme = defaultTheme;
const darkTheme = { ...defaultTheme, palette: { mode: 'dark' } };
function App() {
  return (
    <Admin 
    dataProvider={dataProvider} 
    authProvider={authProvider} 
    loginPage={MyLoginPage} 
    theme={darkTheme}
        >

      <Resource name="users" list={UserList} create={UserCreate} edit={UserEdit} recordRepresentation={"name"}/>
      <Resource name="Plans" list={PlansList} create={PlansCreate} edit={PlansEdit}/>
      <Resource name="Dashboards" list={ListGuesser} />
    </Admin>
  );
}

export default App;
