import { Datagrid, List, TextField, Create, ResourceComponentInjectedProps, SimpleForm, TextInput, Edit, NumberInput } from 'react-admin';

interface PlansAtt {
    name: string;
    description: string;
    accessTime: number;  // days
    numberOfBoards: number;
    numberOfAccesses: number;
    kiwifyProductName: string;
}

export const PlansList = () => (
    <List>
        <Datagrid rowClick="edit">
            <TextField source="_id" />
            <TextField source="name" />
            <TextField source="description" />
            <TextField source="accessTime" />
            <TextField source="numberOfBoards" />
            <TextField source="numberOfAccesses" />
            <TextField source="kiwifyID"  />
        </Datagrid>
    </List>
);

export const PlansCreate = (props: ResourceComponentInjectedProps) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="description" />
            <NumberInput source="accessTime" />
            <NumberInput source="numberOfBoards" />
            <NumberInput source="numberOfAccesses" />
            <TextInput source="kiwifyID" />
        </SimpleForm>
    </Create>
);

export const PlansEdit = (props: ResourceComponentInjectedProps) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name"  />
            <TextInput source="description" />
            <NumberInput source="accessTime" />
            <NumberInput source="numberOfBoards" />
            <NumberInput source="numberOfAccesses" />
            <TextInput source="kiwifyID" />
        </SimpleForm>
    </Edit>
);
