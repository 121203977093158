import { Datagrid, EmailField, List, TextField, Create, ResourceComponentInjectedProps, SimpleForm, TextInput, Edit, SearchInput, NumberInput, ChipField, ReferenceField, ReferenceInput, SelectInput } from 'react-admin';

export const UserList = () => (
    <List>
        <Datagrid rowClick="edit">
        <TextField source="_id" />
            <TextField source="name" />
            <EmailField source="email" />
            <TextField source="phone" />
            <TextField source="tokenSigup" />

            <TextField source="plans" />
        </Datagrid>
    </List>
);

export const UserCreate = (props: ResourceComponentInjectedProps) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="email" />
            <TextInput source="phone" />
            <ReferenceInput label="Planos"  source="product_name" reference="plans">
                <SelectInput optionText="name"  />
            </ReferenceInput>
            <NumberInput source="purchase_date" />
        </SimpleForm>
    </Create>
);

export const UserEdit = (props: ResourceComponentInjectedProps) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="email" />
            <TextInput source="phone" />
            <ReferenceInput label="Planos"  source="product_name" reference="plans">
                <SelectInput optionText="name"  />
            </ReferenceInput>
            <TextInput source="tokenSigup" />
            <NumberInput source="purchase_date" />
        </SimpleForm>
    </Edit>
);

// <TextField source="company.name" />