import { AuthProvider } from 'react-admin';
const API_URL = "https://server.launchboard.com.br/admin";

const authProvider : AuthProvider = {
    login: async ({ username, password, token }: { username: string; password: string, token:string }): Promise<void> => {
        const request = new Request(`${API_URL}/authenticate`, {
            method: 'POST',
            body: JSON.stringify({ username, password, token }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });

        try {
            const response = await fetch(request);
            
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            const auth = await response.json();
            console.log("auth", auth);
            
            localStorage.setItem('auth', JSON.stringify(auth));
        } catch {
            throw new Error('Network error');
        }
    },
    logout: () => {
        localStorage.removeItem('auth');
        return Promise.resolve();
    },
        checkAuth: () =>
        localStorage.getItem('auth') ? Promise.resolve() : Promise.reject(),
    checkError:  (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getIdentity: () =>
        Promise.resolve({
            id: 'user',
            fullName: 'John Doe',
        }),
    getPermissions: () => Promise.resolve(''),
};

export default authProvider;